import React, { useEffect, useContext } from "react";
import { gsap } from "gsap";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Lightlogo from "../Assets/Logo-light.png";
import Darklogo from "../Assets/Logo.png";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import Rifaworld_how_it_works from "../Assets/pdf/Rifaworld_how_it_works.pdf";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBarItems: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileMenuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerIcon: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  drawer: {
    width: 300,
  },
}));

function Landing() {
  useEffect(() => {
    gsap.to(".circle", {
      duration: 5,
      repeat: -1,
      motionPath: {
        path: "#animationPath",
        align: "#animationPath",
        alignOrigin: [0.5, 0.5],
      },
    });
  }, []);

  const navigate = useNavigate();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const theme = useTheme();

  const classes = useStyles();

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    var currURL = window.location.href;
    var refferalId = currURL.split("/")[3];

    console.log(refferalId, "jnniucnnidc");
    console.log(refferalId, "jijijmomjifdm");
    if (refferalId != undefined && refferalId != "") {
      var id = refferalId;
      verifyId(id);
    }
  }, [0]);

  const verifyId = async (id) => {
    try {
      var obj = {
        id: id,
      };
      var data = {
        apiUrl: apiService.verifyId,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } catch (err) { }
  };

  const nav_page = () => {
    navigate("/register");
  };

  return (
    <>
      <header className="scrolled">
        <div className="">
          <div className="header_new_mega">
            <div className={`${classes.root} `}>
              <AppBar position="static">
                <Toolbar className="container">
                  <Typography variant="h6" className={classes.title}>
                    <div className="logo_new_sectio d-flex">
                      <a href="/" className="logo-brand">
                        <img
                          src={Lightlogo}
                          className="img-fluid "
                          alt="logo"
                        />
                      </a>
                      <div className={`menu_new_typr ${classes.appBarItems}`}>
                        <a
                          href="#home"
                          color="inherit"
                          className="contact_button"
                        >
                          Home
                        </a>
                        <a
                          href="#feature"
                          color="inherit"
                          className="contact_button"
                        >
                          Features
                        </a>{" "}
                        <a
                          href="#overview"
                          color="inherit"
                          className="contact_button"
                        >
                          Overview
                        </a>{" "}
                        <a
                          href="#tutorial"
                          color="inherit"
                          className="contact_button"
                        >
                          Tutorial
                        </a>

                        <a
                          href={Rifaworld_how_it_works}
                          target="_blank"
                          color="inherit"
                          className="contact_button"
                        >
                          How It Works
                        </a>
                      </div>
                    </div>
                  </Typography>

                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    className={` m-0 p-0 ${classes.mobileMenuButton} ${classes.drawerIcon}`}
                  >
                    <MenuIcon onClick={handleMobileMenuOpen} />
                  </IconButton>
                  <div className={`menu_new_typr ${classes.appBarItems}`}>
                    <Button className="nav-btn" onClick={nav_page}>
                      Register
                    </Button>
                  </div>
                </Toolbar>
              </AppBar>
              <Drawer
                anchor="left"
                open={mobileMenuOpen}
                className={classes.drawer}
                onClose={() => handleMobileMenuClose(false)}
              >
                <div className={classes.mobileMenu}>
                  <div className="logo_new_sectio mobile_menu_icon">
                    <Link to="#" className="logo-brand">
                      <img
                        src={Darklogo}
                        className="img-fluid m-3"
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="menu_statis" onClick={() => setMobileMenuOpen(false)}>
                    <ListItem button className="drawa" >
                      <a href="#home"  >Home</a>
                    </ListItem>
                    <ListItem button className="drawa">
                      <a href="#feature">Features</a>
                    </ListItem>
                    <ListItem button className="drawa ">
                      <a href="#overview">Overview</a>
                    </ListItem>

                    <ListItem button className="drawa ">
                      <a href="#tutorial">Tutorial</a>
                    </ListItem>

                    <ListItem button className="drawa ">
                      <a href={Rifaworld_how_it_works} target="_blank">How It Works</a>
                    </ListItem>

                    <ListItem button className="drawa ">
                      <Button onClick={() => navigate("/register")}>
                        Register
                      </Button>
                    </ListItem>
                  </div>
                </div>
              </Drawer>
            </div>
          </div>
        </div>
      </header>
      <div className="Rifa-landing">
        <div className="sec1-main">
          <div className="section1" id="home">
            <div className="container">
              <div className="row m-100 relative daj">
                <img
                  src={require("../Assets/banner.png")}
                  className="absolute"
                />
                <svg
                  width="1140"
                  height="342"
                  viewBox="0 0 1142 344"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="path1"
                    d="M1101 172L598 172"
                    stroke="url(#paint0_linear_1825_5965)"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                  <path
                    id="path2"
                    d="M598 156.627C884.217 165.634 861.239 8.50589 1103 1"
                    stroke="url(#paint1_linear_1825_5965)"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                  <path
                    id="path3"
                    d="M598 186.373C884.217 177.366 861.239 334.494 1103 342"
                    stroke="url(#paint2_linear_1825_5965)"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                  <path
                    id="path4"
                    d="M598 164.817C872.494 169.856 886.469 69.0746 1141 82.1762"
                    stroke="url(#paint3_linear_1825_5965)"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                  <path
                    id="path5"
                    d="M598 179.181C872.494 174.202 886.469 273.783 1141 260.838"
                    stroke="url(#paint4_linear_1825_5965)"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />

                  <circle r="5" fill="url(#paint5_linear_1825_5965)">
                    <animateMotion repeatCount="indefinite" dur="15s">
                      <mpath href="#path1" />
                    </animateMotion>
                  </circle>
                  <circle r="5" fill="url(#paint6_linear_1825_5965)">
                    <animateMotion repeatCount="indefinite" dur="14s">
                      <mpath href="#path2" />
                    </animateMotion>
                  </circle>
                  <circle r="5" fill="url(#paint7_linear_1825_5965)">
                    <animateMotion repeatCount="indefinite" dur="13s">
                      <mpath href="#path3" />
                    </animateMotion>
                  </circle>
                  <circle r="5" fill="url(#paint8_linear_1825_5965)">
                    <animateMotion repeatCount="indefinite" dur="12s">
                      <mpath href="#path4" />
                    </animateMotion>
                  </circle>
                  <circle r="5" fill="url(#paint9_linear_1825_5965)">
                    <animateMotion repeatCount="indefinite" dur="11s">
                      <mpath href="#path5" />
                    </animateMotion>
                  </circle>

                  <path
                    id="path6"
                    d="M41 172L544 172"
                    stroke="url(#paint10_linear_1825_5965)"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                  <path
                    id="path7"
                    d="M544 187.373C257.783 178.366 280.761 335.494 39 343"
                    stroke="url(#paint11_linear_1825_5965)"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                  <path
                    id="path8"
                    d="M544 157.627C257.783 166.634 280.761 9.50589 39 2"
                    stroke="url(#paint12_linear_1825_5965)"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                  <path
                    id="path9"
                    d="M544 179.183C269.506 174.144 255.531 274.925 1 261.824"
                    stroke="url(#paint13_linear_1825_5965)"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                  <path
                    id="path10"
                    d="M544 164.819C269.506 169.798 255.531 70.2166 1 83.1622"
                    stroke="url(#paint14_linear_1825_5965)"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />

                  <circle r="5" fill="url(#paint15_linear_1825_5965)">
                    <animateMotion repeatCount="indefinite" dur="11s">
                      <mpath href="#path6" />
                    </animateMotion>
                  </circle>
                  <circle r="5" fill="url(#paint16_linear_1825_5965)">
                    <animateMotion repeatCount="indefinite" dur="12s">
                      <mpath href="#path7" />
                    </animateMotion>
                  </circle>
                  <circle r="5" fill="url(#paint17_linear_1825_5965)">
                    <animateMotion repeatCount="indefinite" dur="13s">
                      <mpath href="#path8" />
                    </animateMotion>
                  </circle>
                  <circle r="5" fill="url(#paint18_linear_1825_5965)">
                    <animateMotion repeatCount="indefinite" dur="14s">
                      <mpath href="#path9" />
                    </animateMotion>
                  </circle>
                  <circle r="5" fill="url(#paint19_linear_1825_5965)">
                    <animateMotion repeatCount="indefinite" dur="15s">
                      <mpath href="#path10" />
                    </animateMotion>
                  </circle>

                  <defs>
                    <linearGradient
                      id="paint0_linear_1825_5965"
                      x1="1101"
                      y1="172"
                      x2="598"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1825_5965"
                      x1="598.499"
                      y1="156.627"
                      x2="1106.15"
                      y2="2.01255"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#236AFF" />
                      <stop offset="1" stop-color="#6E9CFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_1825_5965"
                      x1="598.5"
                      y1="186"
                      x2="1103.16"
                      y2="341.613"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#236AFF" />
                      <stop offset="1" stop-color="#6E9CFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_1825_5965"
                      x1="598"
                      y1="165.321"
                      x2="1143.23"
                      y2="83.2322"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#236AFF" />
                      <stop offset="1" stop-color="#6E9CFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint4_linear_1825_5965"
                      x1="598"
                      y1="179"
                      x2="1140.94"
                      y2="262.034"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#236AFF" />
                      <stop offset="1" stop-color="#6E9CFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint5_linear_1825_5965"
                      x1="825"
                      y1="231"
                      x2="815"
                      y2="231"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint6_linear_1825_5965"
                      x1="869"
                      y1="219"
                      x2="859"
                      y2="219"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint7_linear_1825_5965"
                      x1="896"
                      y1="172"
                      x2="886"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint8_linear_1825_5965"
                      x1="869"
                      y1="127"
                      x2="859"
                      y2="127"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint9_linear_1825_5965"
                      x1="825"
                      y1="116"
                      x2="815"
                      y2="116"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint10_linear_1825_5965"
                      x1="41"
                      y1="172"
                      x2="544"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint11_linear_1825_5965"
                      x1="544"
                      y1="187.373"
                      x2="39"
                      y2="343"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#236AFF" />
                      <stop offset="1" stop-color="#6E9CFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint12_linear_1825_5965"
                      x1="544"
                      y1="157.627"
                      x2="39"
                      y2="2"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint13_linear_1825_5965"
                      x1="544"
                      y1="179.183"
                      x2="1"
                      y2="261.824"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint14_linear_1825_5965"
                      x1="544"
                      y1="164.819"
                      x2="1"
                      y2="83.1622"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint15_linear_1825_5965"
                      x1="825"
                      y1="231"
                      x2="815"
                      y2="231"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint16_linear_1825_5965"
                      x1="869"
                      y1="219"
                      x2="859"
                      y2="219"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint17_linear_1825_5965"
                      x1="896"
                      y1="172"
                      x2="886"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint18_linear_1825_5965"
                      x1="869"
                      y1="127"
                      x2="859"
                      y2="127"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint19_linear_1825_5965"
                      x1="825"
                      y1="116"
                      x2="815"
                      y2="116"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6E9CFF" />
                      <stop offset="1" stop-color="#236AFF" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>

          <div className="section1" id="home">
            <div className="container">


              <div className="row headbox justify-content-center">
                <div className=" col-lg-2 col-md-3 col-sm-4" >
                  <a >
                    <img src={require("../Assets/headapple.png")} width="100%" />
                  </a>

                </div>
                <div className=" col-lg-2 col-md-3 col-sm-4" >

                  <a href="/rifaworld-release.apk" download>
                    <img src={require("../Assets/headapp.png")} width="100%" />
                  </a>
                </div>
                <div className=" col-lg-2 col-md-3 col-sm-4" >

                  <a >
                    <img src={require("../Assets/headplay.png")} width="100%" />
                  </a>
                </div>
              </div>
              <div className="row relative daj">

                <div className="col-lg-12">
                  <p>Rifa World Trade</p>
                  <h1>Boost Your Investments With Our Trading Bot</h1>

                  <h6>
                    Unlock unmatched trading accuracy and profitability.
                    Automate strategies effortlessly. Download now to optimize
                    your investments.
                  </h6>

                  <button onClick={nav_page}> Get Started </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section2" id="feature">
          <div className="container">
            <div className="topcard">
              <h2>
                Exclusive Features <br /> Of Rifa World Trade Bot
              </h2>

              <div className="row">
                <div className="col-lg-4">
                  <div className="feature-card">
                    <div className="top">
                      <h4>Funds are secure</h4>
                      <p>
                        Rifa World Trade never accesses or withdraws your funds.
                      </p>
                    </div>
                    <div className="imgs">
                      <img src={require("../Assets/bot1.png")} width="70%" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-card">
                    <div className="top">
                      <h4>High Daily Profits Guaranteed</h4>
                      <p>
                        Achieve consistent daily profits with our advanced
                        trading solutions.
                      </p>
                    </div>
                    <div className="imgs">
                      <img src={require("../Assets/bot2.png")} width="90%" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-card">
                    <div className="imgs-left">
                      <img src={require("../Assets/bot3.png")} width="90%" />
                    </div>
                    <div className="top">
                      <h4>Fast trading servers</h4>
                      <p>
                        Our servers are located close to popular exchanges to
                        ensure stable and fast order execution.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section3" id="overview">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <img src={require("../Assets/Banner2.png")} width="100%" />
              </div>

              <div className="col-lg-6">
                <h2>Utilize The Advantages Of Rifa World Trade Bot</h2>

                <div className="advanatge">
                  <div>
                    <img src={require("../Assets/ref.png")} width="40px" />
                  </div>
                  <div className="content">
                    <h3>Referral Program</h3>
                    <p>
                      Earn up to 60% in referral fees from user payments
                      allocated to our referral program
                    </p>
                  </div>
                </div>

                <div className="advanatge">
                  <div>
                    <img src={require("../Assets/payment.png")} width="40px" />
                  </div>
                  <div className="content">
                    <h3>Life-Time Payments</h3>
                    <p>
                      Earn ongoing commissions from your users as long as they
                      continue using Rifa World Trade.
                    </p>
                  </div>
                </div>

                <div className="advanatge">
                  <div>
                    <img src={require("../Assets/support.png")} width="40px" />
                  </div>
                  <div className="content">
                    <h3>Support</h3>
                    <p>
                      Gain access to comprehensive marketing and financial
                      support tailored to enhance your promotion campaigns.
                    </p>
                  </div>
                </div>

                <div className="advanatge">
                  <div>
                    <img src={require("../Assets/payouts.png")} width="40px" />
                  </div>
                  <div className="content">
                    <h3>Instant Payouts</h3>
                    <p>
                      Request instant payouts whenever you need them and receive
                      your funds promptly without delay.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section4" id="tutorial">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <h2>Rifa World Trade App Tutorial</h2>
                <p>
                  Learn how to navigate and make the most of your Rifa World
                  Trade app with our comprehensive video tutorials and
                  step-by-step guides.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="video-card">
                  <div className="video"></div>

                  <div className="context">
                    <h3>Introduction to Rifa World Trade</h3>
                    <p>
                      A comprehensive tutorial video to help you get started
                      with our crypto trading bot.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="video-card">
                  <div className="video"></div>

                  <div className="context">
                    <h3>How To Register on Rifa World Trade</h3>
                    <p>
                      Quick guide to creating your account on Rifa World Trade.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="video-card">
                  <div className="video"></div>

                  <div className="context">
                    <h3>Introduction to Rifa World Trade</h3>
                    <p>
                      A comprehensive tutorial video to help you get started
                      with our crypto trading bot.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="video-card">
                  <div className="video"></div>

                  <div className="context">
                    <h3>Introduction to Rifa World Trade</h3>
                    <p>
                      A comprehensive tutorial video to help you get started
                      with our crypto trading bot.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="video-card">
                  <div className="video"></div>

                  <div className="context">
                    <h3>How To Register on Rifa World Trade</h3>
                    <p>
                      Quick guide to creating your account on Rifa World Trade.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="video-card">
                  <div className="video"></div>

                  <div className="context">
                    <h3>Introduction to Rifa World Trade</h3>
                    <p>
                      A comprehensive tutorial video to help you get started
                      with our crypto trading bot.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section5">
          <div className="container">
            <div className="install">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={require("../Assets/Install.png")}
                    className="images"
                    width="50%"
                  />
                </div>
                <div className="col-lg-6">
                  <h2>
                    Install Rifa World Trade <br />
                    And Get Started
                  </h2>
                  <p>
                    Quickly build, test, and run crypto trading bots with Rifa
                    World Trade. Free and versatile options available!
                  </p>

                  <div className="playstore">
                    <img src={require("../Assets/apple.png")} width="30%" />
                    <img src={require("../Assets/playstore.png")} width="30%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section6">
          <div className="container">
            <div className="row FAQs">
              <div className="col-lg-6">
                <h2>Have Question About Rifa World Trade?</h2>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Is Rifa World Trade Safe ?
                  </AccordionSummary>
                  <AccordionDetails>
                    Yes, Rifa World Trade was built with your security in mind
                    from the beginning. Every API key is securely encrypted and
                    stored using FIPS 140-2 validated hardware security modules
                    (HSMs) to protect the confidentiality and integrity of your
                    exchange API keys. Rifa World Trade only requires the
                    ability to read data and make trades, so your funds can't be
                    removed from the exchange. We also encourage all users to
                    utilize our Two-Factor Authentication (“2FA”) service which
                    secures access to your account.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    What is Rifa World Trading?
                  </AccordionSummary>
                  <AccordionDetails>
                    Rifa World Trade is a crypto trading bot that automates your
                    trades round the clock. Never miss an opportunity. Create a
                    bot in minutes. Run and manage it, anytime, anywhere. Rifa
                    World Trade is essentially a program that use artificial
                    intelligence (AI) and mssachine learning to determine the
                    best times to execute specific cryptocurrency trades.
                    uTrading automatically perform these trades on your behalf
                    according to predefined algorithms. Rifa World Trade is
                    designed to maximize returns by leveraging AI to know what
                    trades to do and when to do them.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    Do I need to deposit transaction funds to Rifa World Trade?
                  </AccordionSummary>
                  <AccordionDetails>
                    No, Rifa World Trade will never ask you to deposit
                    transaction funds. All of your transaction funds should be
                    maintained on exchanges that are connected to Rifa World
                    Trade. Once a exchange is connected, you are Rifa World
                    Trade can view those balances and execute trades.
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    Can I earn passive income by sharing Rifa World Trade?
                  </AccordionSummary>
                  <AccordionDetails>
                    Yes, of course!
                    <br />
                    The Rifa World Trade referral system includes a multi-tiered
                    network of commission programs, which means that you not
                    only get rewarded for your direct referrals, but also
                    benefit from their referral activity. Assuming you (VIP 1)
                    have invited three friends, you will each receive 20% of the
                    energy they paid. These are your direct referral rewards.
                    Then, each of them invites their friends as well. Once their
                    (VIP 0) referrals buy energy, you will receive 20% of the
                    commission paid by them. An active referral network will
                    grow, not only your direct referrals, but also earn more
                    commissions from their referrals, multiplying your income!
                    Be sure to use your own referral link to invite others and
                    encourage them to refer others. This way, you will maximize
                    your returns! Tips on how to grow your referral network
                    faster: * Share the referral link with your friends on
                    Facebook and Twitter * Create a YouTube video review about
                    the platform * Publish a blog describing the benefits of
                    trading on Rifa World Trade * Join forums and
                    Telegram/Facebook groups for trading related discussions and
                    promoting your referral link
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    Is profit guaranteed?
                  </AccordionSummary>
                  <AccordionDetails>
                    Unfortunately, the only guaranteed things in life are death
                    and taxes. While trading bots confer many advantages for
                    their users, they cannot guarantee profits. The
                    cryptocurrency market is highly volatile and speculative,
                    and any trading activity is ultimately done at your own
                    risk. Always remember the golden rule: never invest more
                    than you can afford to lose.
                  </AccordionDetails>
                </Accordion>
              </div>

              <div className="col-lg-6">
                <img
                  src={require("../Assets/FAQ.png")}
                  width="70%"
                  className="d-block mx-auto"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-md-10">
                    <img
                      src={require("../Assets/footer-logo.png")}
                      width="100%"
                    />

                    <p>
                      Your gateway to seamless and smart crypto trading.
                      Experience the power of automated trading bots today.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row justify-content-center">
                  <div className="col-md-4 foots">
                    <h5>Quick links</h5>

                    <h6>
                      <a href="#home">Home</a>
                    </h6>
                    <h6>
                      <a href="#feature">Features</a>
                    </h6>
                    <h6>
                      <a href="#overview">Overview</a>
                    </h6>
                    <h6>
                      <a href="#tutorial">Tutorial</a>
                    </h6>
                    <h6>
                      <a href={Rifaworld_how_it_works} target="_blank">How It Works</a>
                    </h6>
                  </div>
                  <div className="col-md-4">
                    <h5>Socials</h5>

                    <h6>Telegram</h6>
                    <h6>Twitter </h6>
                    <h6>Facebook</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 hrs">
                <div className="copy">
                  Copyright © 2024. Rifa All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;
